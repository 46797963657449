<template>
  <div>
    <vx-card title="Faire une demande" class="mb-4">
      <div>
        <div class="p-8">
          <div>
            <div
              class="flex flex-wrap-reverse items-center data-list-btn-container"
            >
              <div>
                <vs-button
                  v-if="checkUserRole('ticket').create"
                  @click="OpenModalTicket()"
                  color="primary"
                  type="filled"
                  icon-pack="feather"
                  icon="icon-plus"
                  >Ajouter</vs-button
                >
                <vx-tooltip
                  color="primary"
                  :title="role.notAllowTitle"
                  :text="role.notAllowText"
                  v-else
                >
                  <vs-button
                    :disabled="true"
                    color="primary"
                    type="filled"
                    icon-pack="feather"
                    icon="icon-plus"
                    >Ajouter</vs-button
                  >
                </vx-tooltip>
              </div>
              <div>
                <div>
                  <vs-dropdown
                    vs-trigger-click
                    class="dd-actions cursor-pointer ml-4"
                  >
                    <div
                      class="p-3 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full"
                    >
                      <span class="mr-2">Afficher {{ limit }}</span>
                      <feather-icon
                        icon="ChevronDownIcon"
                        svgClasses="h-4 w-4"
                      />
                    </div>
                    <vs-dropdown-menu>
                      <vs-dropdown-item
                        :key="i"
                        v-for="(r, i) in rowsTable"
                        @click="limit = r"
                      >
                        <span class="flex items-center">
                          <span>{{ r }}</span>
                        </span>
                      </vs-dropdown-item>
                    </vs-dropdown-menu>
                  </vs-dropdown>
                </div>
              </div>
            </div>
            <div class>
              <div class="w-auto" v-if="loading">
                <clip-loader :loading="loading" color="primary"></clip-loader>
              </div>
              <div v-else>
                <div v-if="allTickets.list.length !== 0">
                  <vs-table
                    multiple
                    @dblSelection="ticketSelectedDouble"
                    :data="allTickets.list"
                    v-model="ticketSelected"
                    search
                    stripe
                    pagination
                    :max-items="limit != 'Tout' ? limit : total"
                    noDataText="Aucun résultat trouvé"
                  >
                    <template slot="thead">
                      <vs-th>N°</vs-th>
                      <vs-th sort-key="ref">Référence</vs-th>
                      <vs-th sort-key="createdAt">Date</vs-th>
                      <vs-th sort-key="contrat">Contrat</vs-th>
                      <vs-th sort-key="object">Objet</vs-th>
                      <vs-th sort-key="state">Etat</vs-th>
                      <vs-th>Actions</vs-th>
                    </template>
                    <template slot-scope="{ data }">
                      <vs-tr
                        :data="tr"
                        :key="indextr"
                        v-for="(tr, indextr) in data"
                      >
                        <vs-td :data="data[indextr].id">{{
                          indextr + 1
                        }}</vs-td>
                        <vs-td :data="data[indextr].ref">{{
                          data[indextr].ref
                        }}</vs-td>
                        <vs-td :data="data[indextr].createdAt"
                          >{{ dateElement(data[indextr]).createdAtInfo }}
                        </vs-td>
                        <vs-td :data="data[indextr].contrat">{{
                          data[indextr].contrat
                        }}</vs-td>
                        <vs-td :data="data[indextr].object" style="">{{
                          subString(data[indextr].object)
                        }}</vs-td>
                        <vs-td :data="data[indextr].status">
                          <div class="vx-row sm:flex hidden mt-4">
                            <div class="vx-col w-full flex">
                              <!-- legend -->
                              <div
                                class="flex flex-wrap sm:justify-start justify-center"
                              >
                                <div class="flex items-center mr-4 mb-2">
                                  <div
                                    class="h-5 w-5 inline-block rounded-full mr-2"
                                    :style="{
                                      background: crrBackgroundColor(
                                        data[indextr].status
                                      ).background
                                    }"
                                  ></div>
                                  <span>{{
                                    crrBackgroundColor(data[indextr].status)
                                      .text
                                  }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </vs-td>
                        <vs-td :data="data[indextr].id">
                          <div class="inline-flex" @click.stop>
                            <!--<vx-tooltip
                              position="left"
                              color="primary"
                              :title="aboutElement(data[indextr]).title"
                              :text="aboutElement(data[indextr]).text"
                            >
                              <div class="cursor-pointer">
                                <feather-icon
                                  icon="InfoIcon"
                                  class="text-primary"
                                  svgClasses="w-6 h-6"
                                ></feather-icon>
                              </div>
                            </vx-tooltip>-->

                            <vs-dropdown class="dd-actions cursor-pointer">
                              <div class=" cursor-pointer">
                                <feather-icon
                                  icon="AlignJustifyIcon"
                                  svgClasses="w-6 h-6"
                                ></feather-icon>
                              </div>
                              <vs-dropdown-menu>
                                <div>
                                  <vs-dropdown-item
                                    v-if="checkUserRole('ticket').show"
                                  >
                                    <div
                                      class="ml-1 cursor-pointer"
                                      @click="checkViewTicket(data[indextr])"
                                    >
                                      <span class="flex items-center">
                                        <feather-icon
                                          icon="EyeIcon"
                                          class="text-primary"
                                          svgClasses="w-6 h-6"
                                        ></feather-icon>
                                        <span class="m-3">Ouvrir </span>
                                      </span>
                                    </div>
                                  </vs-dropdown-item>
                                  <vx-tooltip
                                    v-else
                                    color="primary"
                                    :title="role.notAllowTitle"
                                    :text="role.notAllowText"
                                  >
                                    <vs-dropdown-item disabled>
                                      <div class="ml-1 cursor-pointer">
                                        <span class="flex items-center">
                                          <feather-icon
                                            icon="EyeIcon"
                                            class="text-primary"
                                            svgClasses="w-6 h-6"
                                          ></feather-icon>
                                          <span class="m-3">Ouvrir </span>
                                        </span>
                                      </div>
                                    </vs-dropdown-item>
                                  </vx-tooltip>
                                </div>

                                <div>
                                  <vs-dropdown-item
                                    v-if="checkUserRole('ticket').show"
                                  >
                                    <div
                                      class="ml-1 cursor-pointer"
                                      @click="downloadTicket(data[indextr])"
                                    >
                                      <span class="flex items-center">
                                        <feather-icon
                                          icon="DownloadIcon"
                                          class="text-primary"
                                          svgClasses="w-6 h-6"
                                        ></feather-icon>
                                        <span class="m-3">Télécharger </span>
                                      </span>
                                    </div>
                                  </vs-dropdown-item>
                                  <vx-tooltip
                                    v-else
                                    color="primary"
                                    :title="role.notAllowTitle"
                                    :text="role.notAllowText"
                                  >
                                    <vs-dropdown-item disabled>
                                      <div class="ml-1 cursor-pointer">
                                        <span class="flex items-center">
                                          <feather-icon
                                            icon="DownloadIcon"
                                            class="text-primary"
                                            svgClasses="w-6 h-6"
                                          ></feather-icon>
                                          <span class="m-3">Télécharger </span>
                                        </span>
                                      </div>
                                    </vs-dropdown-item>
                                  </vx-tooltip>
                                </div>

                                <div>
                                  <vs-dropdown-item
                                    :disabled="data[indextr].status === 'close'"
                                    v-if="checkUserRole('ticket').update"
                                  >
                                    <div
                                      class="ml-1 cursor-pointer"
                                      @click="ChangeStatuts(data[indextr])"
                                    >
                                      <span class="flex items-center">
                                        <feather-icon
                                          :icon="tooltipIconText(data[indextr])"
                                          class="text-primary"
                                          svgClasses="w-6 h-6"
                                        ></feather-icon>
                                        <span class="m-3">
                                          Fermer
                                        </span>
                                      </span>
                                    </div>
                                  </vs-dropdown-item>
                                  <vx-tooltip
                                    v-else
                                    color="primary"
                                    :title="role.notAllowTitle"
                                    :text="role.notAllowText"
                                  >
                                    <vs-dropdown-item disabled>
                                      <div class="ml-1 cursor-pointer">
                                        <span class="flex items-center">
                                          <feather-icon
                                            :icon="
                                              tooltipIconText(data[indextr])
                                            "
                                            class="text-primary"
                                            svgClasses="w-6 h-6"
                                          ></feather-icon>
                                          <span class="m-3">
                                            Fermer
                                          </span>
                                        </span>
                                      </div>
                                    </vs-dropdown-item>
                                  </vx-tooltip>
                                </div>
                              </vs-dropdown-menu>
                            </vs-dropdown>

                            <div
                              class="ml-2"
                              v-show="woIsAction == data[indextr].id"
                            >
                              <clip-loader
                                :loading="loadingUpdateTicket"
                                color="primary"
                                size="25px"
                              ></clip-loader>
                            </div>
                          </div>
                        </vs-td>
                      </vs-tr>
                    </template>
                  </vs-table>
                  <vs-pagination
                    class="mt-6"
                    :total="allPages"
                    v-model="currentPage"
                  ></vs-pagination>

                  <div class="vx-row sm:flex hidden mt-4">
                    <div class="vx-col w-full flex">
                      <!-- legend -->
                      <div
                        class="flex flex-wrap sm:justify-start justify-center"
                      >
                        <div
                          v-for="(status, index) in ticketStatus"
                          :key="index"
                          class="flex items-center mr-4 mb-2"
                        >
                          <div
                            class="h-3 w-3 inline-block rounded-full mr-2"
                            :style="{ background: status.color }"
                          ></div>
                          <span>{{ status.text }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="flex items-center justify-center mt-8">
                    <strong>Aucun enregistrement</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <vs-popup
            class="holamundo"
            title="Faire une demande"
            :active.sync="popupAddTicketActive"
          >
            <div class="w-auto" v-if="loadingAllContrat">
              <clip-loader :loading="loadingAllContrat" color="primary"></clip-loader>
            </div>
            <div v-else>
              <div class="m-4">
                <div class="clearfix">
                  <div>
                    <vs-row vs-w="12">
                      <vs-col
                        class="pr-4"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="12"
                        vs-sm="12"
                        vs-xs="12"
                      >
                        <div class="w-full">
                          <span class="pl-2">Contrat*</span>
                          <multiselect
                            v-model="form.contrat"
                            :options="allContrat"
                            placeholder="--Choisir--"
                            label="label"
                            track-by="label"
                            v-validate="'required'"
                            name="Contrat"
                            required
                            :selectLabel="global.selectLabel"
                            :selectedLabel="global.selectedLabel"
                            :deselectLabel="global.deselectLabel"
                          >
                            <template v-slot:noOptions>
                              <span>{{ global.selectNotFoundData }}</span>
                            </template>
                          </multiselect>

                          <div class="aab-vie-error-msg">{{
                            errors.first("Contrat")
                          }}</div>
                        </div>
                      </vs-col>

                      <vs-col
                        class="pr-4 mt-2 mb-2"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="12"
                        vs-sm="12"
                        vs-xs="12"
                      >
                        <div class="w-full">
                          <span class="pl-2">Objet*</span>
                          <multiselect
                            v-model="form.object"
                            :options="objectListField"
                            placeholder="--Choisir--"
                            label="label"
                            track-by="label"
                            v-validate="'required'"
                            name="Objet"
                            required
                            :selectLabel="global.selectLabel"
                            :selectedLabel="global.selectedLabel"
                            :deselectLabel="global.deselectLabel"
                          >
                            <template v-slot:noOptions>
                              <span>{{ global.selectNotFoundData }}</span>
                            </template>
                          </multiselect>

                          <div class="aab-vie-error-msg">{{
                            errors.first("Objet")
                          }}</div>
                        </div>
                      </vs-col>

                      <vs-col
                        class="pr-4 mt-2 mb-2"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="12"
                        vs-sm="12"
                        vs-xs="12"
                      >
                        <div class="w-full">
                          <div
                            class="flex mt-2 mb-3"
                            style="flex-direction: column;"
                          >
                            <div class="w-full">
                              <vs-textarea
                                v-model="form.description"
                                label="Message* : "
                                v-validate="'required'"
                                name="message"
                              />
                              <div class="aab-vie-error-msg">
                                {{ errors.first("message") }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </vs-col>
                      <vs-col
                          vs-justify="center"
                          vs-align="center"
                          vs-lg="12"
                          vs-sm="12"
                          vs-xs="12"
                          class="mb-6"
                        >
                          <div
                            class="w-full"
                            style="display: flex;
                            align-items: flex-end;
                            justify-content: space-between;"
                          >
                            <div>
                              <div>
                                <div class=" mb-4 ml-2">
                                  <span>Fichier(s)</span>
                                </div>
                                <div>
                                  <div uk-form-custom class="ebsi-upload-file">
                                    <input
                                      multiple
                                      type="file"
                                      :value="resetFilesUpload"
                                      v-on:change="checkUploadFile"
                                    />
                                    <button
                                      style="cursor:pointer"
                                      class="uk-button uk-button-default"
                                      type="button"
                                      tabindex="-1"
                                    >
                                      Choisir vos fichiers
                                    </button>
                                  </div>
                                </div>
                              </div>

                              <span
                                class=" aab-vie-error-msg"
                                v-show="formError"
                                >Erreur lors du chargement du fichier</span
                              >
                            </div>
                          </div>

                          <div
                            class="mt-6 mb-3"
                            style="
                          display: flex;
                          flex-direction: column;"
                          >
                            <div
                              v-for="(file, index) in form.filesname"
                              :key="index"
                              class="center"
                            >
                              <div class="flex">
                                {{ index + 1 }})

                                <div v-if="file.filesname && file.name">
                                  <b
                                    @click="checkViewFile(file)"
                                    class="cursor-pointer"
                                    >{{ subStringTwo(file.name) }}</b
                                  >
                                </div>
                                <div v-else>
                                  <b>{{ subStringTwo(file) }}</b>
                                </div>

                                <vx-tooltip color="primary" text="Supprimer">
                                  <div
                                    class="ml-8 cursor-pointer"
                                    @click="removeFile(index)"
                                  >
                                    <feather-icon
                                      icon="Trash2Icon"
                                      svgClasses="w-6 h-6"
                                    ></feather-icon>
                                  </div>
                                </vx-tooltip>
                              </div>
                            </div>
                            <div class="mt-2">
                              <p class="text-danger">* Champ obligatoire</p>
                            </div>
                          </div>
                        </vs-col>
                    </vs-row>
                  </div>
                </div>
                <div class="mt-8">
                  <div class="inline-flex">
                    <div>
                      <vs-button
                        class="w-full sm:w-auto"
                        :disabled="!validateFormAddTicket || loadingAddTicket"
                        @click="fetchApplyAddTicket"
                        >Envoyer</vs-button
                      >
                      <vs-button
                        class="w-full sm:w-auto ml-4"
                        type="border"
                        @click="popupAddTicketActive = false"
                        >Annuler</vs-button
                      >
                    </div>
                    <div class="ml-2">
                      <clip-loader
                        :loading="loadingAddTicket"
                        color="primary"
                      ></clip-loader>
                    </div>
                  </div>
                  <div class="mt-2">
                    <p class="text-danger">* Champ obligatoire</p>
                  </div>
                </div>
              </div>
            </div>
          </vs-popup>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { en, fr } from "vuejs-datepicker/dist/locale";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { mixin } from "@/mixins/mixin";
import role from "@/config/role";
import moment from "@/helpers/moment";
import global from "@/config/global";
import Multiselect from "vue-multiselect";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import jsPDF from "jspdf";

export default {
  data() {
    return {
      ticketStatus: [
        { text: "Nouveau", color: "#F2E205" },
        { text: "Répondu", color: "#378C0F" },
        { text: "Fermé", color: "#D91828" }
      ],
      objectListField: [
        { id: "Devis", label: "Devis" },
        { id: "Indemnisation", label: "Indemnisation" },
        { id: "Rachat", label: "Rachat" },
        { id: "Remboursement", label: "Remboursement" },
        { id: "Autre", label: "Autre" },
      ],
      crrItem: {},
      intermediariesTmp: [],
      allContrat: [],
      loadingAllContrat: false,
      role: {},
      global: {},
      form: {
        product: "",
        contrat: {},
        object: {},
        description: "",
        filesname: [],
      },
      //Pagination
      limit: 1,
      skip: 0,
      currentPage: 1,
      total: 0,
      allPages: 1,
      //Pagination
      loading: false,
      loadingUpdateTicket: false,
      loadingAddTicket: false,
      error: false,
      errorMsg: "",
      crrID: "",
      woIsAction: "",

      //export section
      fileName: "",
      formats: ["xlsx", "csv", "txt", "pdf"],
      cellAutoWidth: true,
      selectedFormat: "xlsx",
      headerTitle: ["N°", "Réf", "Date", "Produit", "Objet"],
      headerVal: ["index", "ref", "createdAt", "product", "object"],
      activePrompt: false,
      //end export section
      popupAddTicketActive: false,
      ticketSelected: [],
      crrIDs: [],
      resetFilesUpload: "",
      fileData: [],
      formData: [],
      formError: false,
    };
  },
  created() {
    // add lang in validation fields
    Validator.localize("fr", VeeValidateFR);
    this.role = role;
    this.global = global;
  },
  mounted() {
    if (!this.checkUserRole("ticket").all) this.$router.push({ name: "Home" });
    this.limit = this.rowsTable[1];

    this.fetchApplyGetAllContrat()
  },
  computed: {
    ...mapGetters("auth", ["user", "userId"]),
    ...mapGetters("ticket", ["allTickets"]),
    ...mapGetters("general", ["rowsTable", "allProduct"]),
    validateFormAddTicket() {
      return (
        !this.errors.any() &&
        this.form.object != "" &&
        this.form.description != "" &&
        this.form.contrat != ""
      );
    }
  },
  methods: {
    ...mapActions("general", ["applyUploadFile"]),
    ...mapActions("auth", ["logout"]),
    ...mapActions("ticket", [
      "applyGetTicket",
      "applyGetAllTicket",
      "applyAddTicket",
      "applyPutTicket"
    ]),
    ...mapActions("projetContrat", ["applyGetAllProjetContrat"]),
    OpenModalTicket(){
      this.popupAddTicketActive = true 
    },
    tooltipIconText(data) {
      return data.status === "answered" || data.status === "new"
        ? "UnlockIcon"
        : "LockIcon";
    },
    getProduct(productId) {
      return this.allProduct.find(product => product.id == productId).label;
    },
    crrBackgroundColor(stat) {
      let color = { background: "", text: "", color: "" };
      switch (stat) {
        case "new":
          color = { background: "#F2E205", text: "Nouveau ", color: "white" };
          break;
        case "answered":
          color = { background: "#378C0F", text: " Répondu ", color: "white" };
          break;
        case "close":
          color = { background: "#D91828", text: " Fermé", color: "white" };
          break;

        default:
          break;
      }
      return color;
    },
    acceptAlert(data) {
      let newdata = { ...data };
      this.$vs.notify(newdata);
    },
    checkUserRole(pack) {
      return role[this.user.type][pack];
    },
    subString(data) {
      if (data != undefined && data.length > 30) {
        return `${data.substring(0, 30)} ...`;
      } else {
        return data;
      }
    },
    subStringTwo(data) {
      if (data.length > 40) {
        return `${data.substring(0, 40)} ...`;
      } else {
        return data;
      }
    },
    removeFile(data) {
      this.formData.splice(data, 1);
      this.form.filesname.splice(data, 1);
    },
    checkViewFile(item) {
      window.open(this.showFileData(item.filename), "_blank");
    },
    showFileData(data) {
      return `${this.$config.serverURL}/downloads/${data}`;
    },
    async uploadedFiles() {
      let _this = this;
      let productPromises = [];
      this.formData.forEach(function(ele, index) {
        productPromises.push(
          (async function(ele) {
            const res = await _this.applyUploadFile(ele);
            _this.fileData.push({
              filename: res.data[0].uploadedName,
              name: res.data[0].filename
            });
          })(ele)
        );
      });
      await Promise.all(productPromises);
    },
    // fichier multiple ajout
    checkUploadFile(evt) {
      const evtLength = evt.target.files.length;
      let allFilesChecked = [];
      let formDataLength = this.formData.length;
      for (let i = 0; i < evtLength; i++) {
        let formDataTmp = new FormData();
        formDataTmp.append("uri", evt.target.files[i]);
        this.formData[formDataLength + 1] = formDataTmp;
        this.formError = false;
        allFilesChecked.push(evt.target.files[i].name);
        formDataLength++;
      }
      if (this.form.filesname) {
        this.form.filesname = [...this.form.filesname, ...allFilesChecked];
      } else {
        this.form.filesname = [...allFilesChecked];
      }
    },
    lastLogin(data) {
      return mixin.methods.lastLogin(data.lastLoginAt);
    },
    aboutElement(data) {
      return mixin.methods.aboutElement(data);
    },

    downloadTicket(item) {
      let headerValTxt = ["object", "description", "date"];
      let headerTitleTxt = ["Objet", "Message", "Date"];
      mixin.methods.exportToData(
        [
          {
            object: item.object,
            description: item.description,
            date: this.dateElement(item).createdAtInfo
          }
        ],
        headerTitleTxt,
        headerValTxt,
        `${item.object}_${item.ref}`,
        true,
        "xlsx"
      );

      let commentsTab = [];

      item.commentsTicket.forEach(el => {
        console.log(`${el.user}  ${el.user}`);
        commentsTab.push({
          autor: `${el.user.person.firstname}  ${el.user.person.lastname}`,
          comment: el.content
        });
      });

      let headerValxls = ["autor", "comment"];
      let headerTitlexls = ["Auteur", "Commentaire"];
      mixin.methods.exportToData(
        commentsTab,
        headerTitlexls,
        headerValxls,
        `${item.object}_${item.ref}`,
        true,
        "xlsx"
      );
    },
    checkViewTicket(item) {
      setTimeout(() => {
        this.$router.push({
          name: "Comment",
          params: { ticketId: item.id }
        });
      }, 100);
    },
    exportToData() {
      if (this.selectedFormat == "pdf") {
        let tableToPrintPdf = [];

        this.allTickets.list.forEach((el, index) => {
          let jsonTmp = {
            index: index + 1,
            ref: el.ref,
            createdAt: this.dateElement(el).createdAtInfo,
            product: this.getProduct(el.product),
            object: el.object
          };
          tableToPrintPdf.push(jsonTmp);
        });

        let headerArray = [];

        this.headerTitle.forEach((title, index) => {
          headerArray.push({ header: title, dataKey: this.headerVal[index] });
        });
        mixin.methods.exportToPdf(
          [...tableToPrintPdf],
          headerArray,
          this.fileName,
          "LES TICKETS"
        );
      } else {
        let tableToPrint = [];
        this.allTickets.list.forEach((el, index) => {
          let jsonTmp = {
            ref: el.ref,
            createdAt: this.dateElement(el).createdAtInfo,
            product: this.getProduct(el.product),
            object: el.object,
            status: this.crrBackgroundColor(el.status).text
          };
          tableToPrint.push(jsonTmp);
        });

        let headerValTmp = ["ref", "createdAt", "product", "object", "status"];

        let headerTitleTmp = ["Réf", "Date", "Produit", "Objet", "Statut"];

        mixin.methods.exportToData(
          tableToPrint,
          headerTitleTmp,
          headerValTmp,
          this.fileName,
          this.cellAutoWidth,
          this.selectedFormat
        );
      }
      this.clearFields();
    },
    clearFields() {
      (this.filename = ""),
        (this.cellAutoWidth = true),
        (this.selectedFormat = "xlsx");
    },

    dateElement(data) {
      return mixin.methods.dateElement(data);
    },
    async fetchApplyGetAllTicket(data) {
      this.loading = true;

      try {
        await this.applyGetAllTicket(data);

        this.total = this.allTickets.total;
        let p = Math.ceil(this.total / this.limit);
        this.allPages = p ? p : 1;

        this.loading = false;
        this.error = false;
        this.errorMsg = "";
      } catch (err) {
        this.loading = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach(item => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Votre nom d'utilisateur n'existe pas";
                  this.acceptAlert("danger", "Utilisateurs", this.errorMsg);
                  break;

                default:
                  this.acceptAlert(
                    "danger",
                    "Utilisateurs",
                    "Une erreur s'est produite"
                  );
                  break;
              }
            });
          }
        }
      }
    },
    async fetchApplyGetAllContrat(data) {
      this.loadingAllContrat = true;

      try {
        let x = await this.applyGetAllProjetContrat({
          limit: "Tout",
          skip: 0,
          client: this.user.id,
          status: ["active", "inactive", "pendingInactive"],
        })

        if(x.list) {
          x.list.forEach(el => {
            let y = el.productName + " - " + el.refContrat
            this.allContrat.push({ id: y, label: y })
          })
        }

        this.loadingAllContrat = false;

      } catch (err) {
        this.loadingAllContrat = false;
        console.log('error', err)
      }
    },
    async fetchApplyAddTicket() {
      this.loadingAddTicket = true;

      if (this.form.filesname.length > 0) {
        await this.uploadedFiles()
          .then(res => {
            this.acceptAlert(mixin.methods.message("ticket", "file").success);
            this.addTicketed();
          })
          .catch(err => {
            console.log(err);
            this.acceptAlert(
              "danger",
              "Téléchargement",
              "Un problème est survenu lors de la récupération des fichiers"
            );
            this.addTicketed();
          });
      } else {
        this.addTicketed();
      }
    },
    async addTicketed() {
      if (!this.validateFormAddTicket) return false;
      this.loadingAddTicket = true;

      let data = {
        ...this.form,
        object: this.form.object.id,
        contrat: this.form.contrat.id,
        filesname: [...this.fileData]
      };
      console.log("data", data)

      try {
        await this.applyAddTicket(data);
        this.loadingAddTicket = false;
        this.acceptAlert(mixin.methods.message("ticket", "add").success);

        this.fileData = [];
        this.formData = [];
        this.form = {
          product: "",
          contrat: "",
          object: "",
          description: "",
          filesname: [],
        };

        this.popupAddTicketActive = false;
      } catch (err) {
        this.loadingAddTicket = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            this.popupAddTicketActive = false;
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach(item => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Le nom d'utilisateur n'existe pas";
                  this.acceptAlert("danger", "Ajout", this.errorMsg);
                  break;
                case "userNameExistError":
                  this.errorMsg = item.message;
                  this.acceptAlert("danger", "Ajout", this.errorMsg);
                  break;
                case "sendMailError":
                  this.errorMsg = item.message;
                  this.acceptAlert("danger", "Ajout", this.errorMsg);
                  break;

                default:
                  this.acceptAlert(
                    mixin.methods.message("ticket", "add").error
                  );
                  break;
              }
            });
          }
        }
      }
    },
    async fetchApplyUpdatedTicket() {
      this.loadingUpdateTicket = true;
      let data = { ticketId: this.crrItem.id, status: "close" };
      try {
        await this.applyPutTicket(data);
        this.loadingUpdateTicket = false;

        this.acceptAlert(mixin.methods.message("ticket", "close").success);
      } catch (err) {
        this.loadingUpdateTicket = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            this.popupAddTicketActive = false;
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach(item => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Le nom d'utilisateur n'existe pas";
                  this.acceptAlert("danger", "Ajout", this.errorMsg);
                  break;
                case "userNameExistError":
                  this.errorMsg = item.message;
                  this.acceptAlert("danger", "Ajout", this.errorMsg);
                  break;
                case "sendMailError":
                  this.errorMsg = item.message;
                  this.acceptAlert("danger", "Ajout", this.errorMsg);
                  break;

                default:
                  this.acceptAlert(
                    "danger",
                    "Ajout",
                    "Une erreur s'est produite"
                  );
                  break;
              }
            });
          }
        }
      }
    },
    ChangeStatuts(item) {
      this.crrItem = item;
      let text = `Voulez-vous vraiment fermer le ticket portant référence ${item.ref} ?`;

      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirmation`,
        text,
        acceptText: "Confirmer",
        cancelText: "Annuler",
        accept: this.fetchApplyUpdatedTicket
      });
    },
    ticketSelectedDouble(item) {
      if (this.checkUserRole("ticket").show) {
        this.checkViewTicket(item);
      } else {
        this.acceptAlert(
          "danger",
          this.role.notAllowTitle,
          this.role.notAllowText
        );
      }
    }
  },
  watch: {
    currentPage() {
      this.skip = (this.currentPage - 1) * this.limit;

      this.fetchApplyGetAllTicket({
        limit: this.limit,
        skip: this.skip,
        user: this.userId
      });
    },
    limit() {
      this.skip = 0;

      this.fetchApplyGetAllTicket({
        limit: this.limit,
        skip: this.skip,
        user: this.userId
      });
    }
  },
  components: {
    ClipLoader,
    Multiselect,
    quillEditor
  }
};
</script>

<style lang="scss">
.aab-vie-icon-clickable {
  display: flex;
  justify-content: space-around;
  cursor: pointer;
}
.vs-dropdown--menu {
  width: 125%;
}
</style>
